import { Injectable } from '@angular/core';
import { PaginatedQuery } from '@common/interfaces/query.interface';
import { QueryObserverResult } from '@ngneat/query';
import { map, Observable } from 'rxjs';
import { CommonService } from './common.service';

export interface NotificationAlertShort {
    id: string;
    content: number;
}

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    private queryName = 'ActiveNotifications';
    private notificationMessage: string = '';
    private alertNotifications$;
    private filter = { take: 10 };

    constructor(private commonService: CommonService) {}

    getNotifications(): Observable<string> {
        if (this.alertNotifications$) {
            this.alertNotifications$.unsubscribe();
        }
        this.alertNotifications$ = this.commonService.queryService
            .getQuery<
                PaginatedQuery<NotificationAlertShort>
            >(this.queryName, this.filter, { injector: this.commonService.injector })
            .result$.pipe(
                map((alert: QueryObserverResult<PaginatedQuery<NotificationAlertShort>, Error>) => {
                    if (!alert.data) return '';
                    const notifications = alert.data.results;
                    if (notifications.length > 0) {
                        this.notificationMessage = notifications.map((o) => `${o.content}`).join(',\t ');
                    } else {
                        this.notificationMessage = '';
                    }
                    return this.notificationMessage;
                })
            );
        return this.alertNotifications$;
    }
}
