import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@common/services/common.service';
import { BaseLoginComponent } from './base-login.component';
@Component({
    templateUrl: './zkt-user-login.component.html'
})
export class ZktUserLoginComponent extends BaseLoginComponent implements OnInit, AfterViewInit {
    constructor(
        commonService: CommonService,
        private router: Router
    ) {
        super(commonService);
    }

    refresh(): void {
        this.refreshPage();
    }

    refreshPage() {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([this.router.url]);
        });
    }

    ngOnInit(): void {}

    override onSubmit() {
        super.onSubmit(true);
    }
}
