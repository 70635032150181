import { codelists } from '@administration/codelists/codelists.service';
import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
    selector: '[pill]'
})
export class PillDirective implements OnChanges {
    // Colors
    dark = '#222';
    light = '#fff';

    green = '#5DD879';
    darkgreen = '#2DBC4E';
    red = '#E56B77';
    darkred = '#D44B59';
    blue = '#59A9FF';
    orange = '#FD9A47';
    amber = '#FFC000';
    yellow = '#FFC107';
    grey = '#CED4DA';
    purple = '#BA55D3';
    black = '#000';
    pink = '#cc68cc';
    violet = '#9966ff';
    lime = '#c0fa90';

    // Formatting
    formatting = {
        maxHeight: '1.45em',
        display: 'block',
        lineHeight: '1.45em',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '0 0.5em 0 0.5em',
        borderRadius: '4px',
        color: this.dark,
        fontWeight: 'bold',
        textAlign: 'center',
        textDecoration: 'none'
    };

    // Status, module & muted input
    @Input() pill: { status: string; module: string };

    el: ElementRef;

    constructor(el: ElementRef) {
        this.el = el;

        // Apply formatting
        for (const prop in this.formatting) {
            if (Object.prototype.hasOwnProperty.call(this.el.nativeElement.style, prop)) {
                this.el.nativeElement.style[prop] = this.formatting[prop];
            }
        }
    }

    ngOnChanges() {
        const backgroundColor = this.pillColor(this.pill);
        this.el.nativeElement.style.backgroundColor = backgroundColor;
        this.el.nativeElement.style.color = this.backgroundToForeground(backgroundColor);
    }

    private backgroundToForeground(color: string): string {
        return [this.dark].includes(color) ? this.light : this.dark;
    }

    private pillColor(input: { status: string; module: string }): string {
        console.log(input.module, input.status);
        const status = input.status?.toUpperCase();
        const module = input.module;

        if (module === 'VesselVisit') {
            switch (status) {
                case 'ANN':
                    return this.blue;
                case 'ARR':
                    return this.green;
                case 'DEP':
                    return this.purple;
                case 'CAN':
                    return this.red;
            }
        }
        if (module === 'GateStatus') {
            switch (status) {
                case '0':
                case '79':
                    return this.green;
                case '1':
                case '76':
                case '77':
                case '78':
                    return this.grey;
                case '4':
                case '5':
                case '85':
                    return this.orange;
                case '80':
                case '82':
                case '89':
                case '96':
                case '99':
                    return this.grey;
                case '81':
                case '83':
                case '84':
                case '97':
                case '98':
                    return this.darkred;
                case '86':
                    return this.red;
                case '75':
                    return this.blue;
                default:
                    return this.grey;
            }
        }

        if (module === codelists.bookingStatus) {
            switch (status) {
                case 'N':
                    return this.lime;
                case 'M':
                    return this.pink;
                case 'B':
                    return this.green;
                case 'C':
                    return this.yellow;
                case 'E':
                    return this.grey;
                case 'F':
                    return this.blue;
                case 'S':
                    return this.purple;
                case 'P':
                    return this.darkgreen;
                case 'D':
                    return this.orange;
                case 'R':
                    return this.violet;
                case 'V':
                case 'U':
                case 'I':
                    return this.darkred;
                default:
                    return this.light;
            }
        }
        return this.grey;
    }
}
