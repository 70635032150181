import { CustomFilter2 } from '@administration/codelists/codelists.interface';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface UpdateData {
    label: string;
    value?: string;
    clear?: boolean; //when you want to create empty codelist
    customFilter2?: CustomFilter2[];
}

export interface ChangeData {
    label: string;
    value: string;
    codelist?: string;
}

@Injectable({ providedIn: 'root' })
export class DialogCommunicationService {
    private valueChangeSubject = new Subject<any>();
    private updateParameterSubject = new Subject<any>();
    private refreshQuerySubject = new Subject<any>();
    valueChange$ = this.valueChangeSubject.asObservable();
    update$ = this.updateParameterSubject.asObservable();
    refresh$ = this.refreshQuerySubject.asObservable();

    emitValueChange(value: ChangeData) {
        this.valueChangeSubject.next(value);
    }

    emitUpdateChange(value: UpdateData) {
        this.updateParameterSubject.next(value);
    }

    emitRefreshChange(label: string) {
        this.refreshQuerySubject.next(label);
    }
}
