import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '@common/services/common.service';
import { TextInputComponent } from '../input/text-input/text-input.component';
import { BaseLoginComponent } from './base-login.component';
@Component({
    templateUrl: './login.component.html'
})
export class LoginComponent extends BaseLoginComponent implements OnInit {
    showDriverLogin: boolean = false;
    agreeToTerms: boolean = false;

    @ViewChild('username', { static: false }) usernameTextbox: TextInputComponent;
    @ViewChild('password', { static: false }) passwordTextbox: TextInputComponent;
    @ViewChild('badgeId', { static: false }) badgeIdTextbox: TextInputComponent;
    @ViewChild('pin', { static: false }) pinTextbox: TextInputComponent;

    constructor(commonService: CommonService) {
        super(commonService);
    }

    requestPasswordReset() {
        if (!this.user.username) {
            this.usernameTextbox.validate();
            this.commonService.toastrNotificationService.show({
                type: 'warning',
                message: "Please enter your username and press 'Forgot password' button again.",
                title: 'Warning'
            });
        } else {
            //TODO does not exist
            //this.commonService.userService.requestPasswordReset(this.user);
            this.commonService.toastrNotificationService.show({
                type: 'success',
                message: 'Email with instructions was sent. Please check your inbox.',
                title: 'Success'
            });
        }
    }

    toggleUserDriver() {
        this.showDriverLogin = !this.showDriverLogin;
    }

    ngOnInit(): void {
        this.commonService.rememberStateService.clear(['languageId', 'darkMode']);
    }

    override onSubmit() {
        this.validateAll();
        super.onSubmit(this.showDriverLogin);
    }

    validateAll() {
        if (this.showDriverLogin) {
            this.badgeIdTextbox.validate();
            this.pinTextbox.validate();
        } else {
            this.usernameTextbox.validate();
            this.passwordTextbox.validate();
        }
    }
}
