import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Setting } from '@common/interfaces/setting.interface';
import { SettingKeyCodes } from '@common/known-types/setting-key.codes';
import { CommonService } from '@common/services/common.service';
import { environment } from '@environments/environment.base';

@Pipe({
    name: 'time'
})
/**
 * Used to transform datetime value to only time value,
 * and format by default time format
 */
export class TimePipe implements PipeTransform {
    defaultTimeFormat: string = environment.settings.appControl.timeFormat;

    constructor(
        private datePipe: DatePipe,
        private commonService: CommonService
    ) {
        this.getDefaultTimeFormat();
    }

    private getDefaultTimeFormat() {
        const settings = this.commonService.queryService.queryClient.getQueryData<Setting[]>(['settings']);
        if (!settings) return;
        const defaultTimeFormat = settings.find(
            (setting: Setting) => setting.key === SettingKeyCodes.TimeFormat
        )?.value;
        if (defaultTimeFormat) this.defaultTimeFormat = defaultTimeFormat;
    }

    transform(value: Date | string): string | null {
        return this.datePipe.transform(value, this.defaultTimeFormat);
    }
}
