import { CustomFilter2 } from '@administration/codelists/codelists.interface';
import { Component, effect, Input, OnDestroy, OnInit, signal } from '@angular/core';
import { CodeList } from '@common/classes/codelist';
import { CodelistPipe } from '@common/pipes/codelist.pipe';
import { CommonService } from '@common/services/common.service';
import { QueryService } from '@common/services/query.service';
import { environment } from '@environments/environment';
import { tapSuccessResult } from '@ngneat/query';
import { debounce } from 'lodash';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { BaseInputComponent } from '../base-input/base-input.component';
import { CodelistInputConfig } from '../input.type';

export enum CodelistLabelFormat {
    LongId = 'LongId',
    LongCode = 'LongCode',
    OnlyId = 'OnlyId',
    OnlyName = 'OnlyName',
    OnlyCode = 'OnlyCode',
    CreateCustom = 'CreateCustom'
}

/**
 * Codelist input component.
 * @param codelist - The codelist name.
 * @param isMultiple - Whether the input is multiple.
 * @param textField - The text field of the codelist.
 * @param valueField - The value field of the codelist.
 * @param popupSettings - The popup settings of the dropdown.
 * @param formatLabel - The function to format the label.
 * @param customFilter - The custom filter function.
 */
@Component({
    selector: 'app-codelist-input',
    templateUrl: './codelist-input.component.html',
    providers: [{ provide: BaseInputComponent, useExisting: CodelistInputComponent }]
})
export class CodelistInputComponent extends BaseInputComponent<string | string[]> implements OnInit, OnDestroy {
    @Input({ required: true }) codelist: CodelistInputConfig['codelist'];
    @Input() isMultiple: CodelistInputConfig['isMultiple'];
    @Input() textField: CodelistInputConfig['textField'] = 'label';
    @Input() valueField: CodelistInputConfig['valueField'] = 'value';
    @Input() popupSettings: CodelistInputConfig['popupSettings'] =
        environment.settings.appControl.dropdown.popupSettings;
    @Input() formatLabel: CodelistInputConfig['formatLabel'] = (item: CodeList) => `${item.customText || item.name}`;
    @Input() customFilter: CodelistInputConfig['customFilter'];
    @Input() customFilter1 = '';
    @Input() customFilter2: CustomFilter2[];
    @Input() defaultLabelFormat: CodelistInputConfig['defaultLabelFormat'] = CodelistLabelFormat.LongId;

    query$;
    options = signal<{ value: string; label: string; [key: string]: any }[]>([]);
    filteredOptions = signal<{ value: string; label: string; [key: string]: any }[]>([]);
    readonly codelistTake = environment.settings.appControl.codelist.take;
    formatedSelectedOptions = '';
    private destroy$ = new Subject<boolean>();

    constructor(
        private queryService: QueryService,
        private codelistPipe: CodelistPipe,
        private commonService: CommonService
    ) {
        super();
        effect(async () => (this.formatedSelectedOptions = await this.formatSelectedOptions(this.value())));
    }

    ngOnInit() {
        this.initializeCodelistLabelFormat();
        this.queryCodelist('');
    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    queryCodelist(filter, customFilter = this.customFilter1, customFilter2: CustomFilter2[] = this.customFilter2) {
        this.query$ = this.queryService.getCodelistQuery({
            name: this.codelist,
            filter,
            customFilter: customFilter,
            customFilter2: customFilter2,
            selectedIds: this.value ? [this.value()].flat() : null,
            injector: this.commonService.injector
        }).result$;
        this.query$
            .pipe(
                tapSuccessResult((data: []) => {
                    this.options.set(
                        data.map((x: any) => ({
                            label: this.formatLabel(x),
                            value: x.id,
                            name: x.name
                        }))
                    );

                    const currentValue = this.isMultiple ? this.value?.[0] : this.value;
                    if (this.customFilter) {
                        this.filteredOptions.set(this.options().filter((x: any) => this.customFilter(x, filter)));
                        return;
                    }
                    this.filteredOptions.set(
                        this.options().filter((x, i) => x.value === currentValue || i < this.codelistTake + 1)
                    );
                }),
                takeUntil(this.destroy$)
            )
            .subscribe();
    }

    queryCodelistDebounced = debounce((filter) => this.queryCodelist(filter), 500);

    async formatSelectedOptions(selected: string | string[]) {
        if (!this.options() || !selected) return null;

        if (this.isMultiple) {
            if (!Array.isArray(selected) || selected.length === 0) {
                return null;
            }
            return await Promise.all(
                selected.map(
                    async (item) => await firstValueFrom(this.codelistPipe.transform(item, this.codelist, false))
                )
            ).then((res) => res.join(', '));
        } else {
            if (Array.isArray(selected)) return null;
            return await firstValueFrom(this.codelistPipe.transform(selected, this.codelist, false));
        }
    }

    private initializeCodelistLabelFormat() {
        switch (this.defaultLabelFormat) {
            case CodelistLabelFormat.LongCode:
                this.formatLabel = (item) => `${item.code} - ${item.customText || item.name}`;
                break;
            case CodelistLabelFormat.LongId:
                this.formatLabel = (item) => `${item.id} - ${item.customText || item.name}`;
                break;
            case CodelistLabelFormat.OnlyId:
                this.formatLabel = (item) => `${item.id}`;
                break;
            case CodelistLabelFormat.OnlyCode:
                this.formatLabel = (item) => `${item.code}`;
                break;
            case CodelistLabelFormat.OnlyName:
                this.formatLabel = (item) => `${item.name || item.customText}`;
                break;
            case CodelistLabelFormat.CreateCustom:
                break;
            default:
                this.formatLabel = (item) => `${item.customText || item.name}`;
        }
    }
}
