<app-button
    class="invisible absolute right-4 top-4 xl:visible"
    variant="secondary"
    size="lg"
    icon="faSolidArrowRotateRight"
    (click)="refresh()">
    Refresh
</app-button>

<div class="flex justify-center">
    <div class="relative w-full rounded-lg bg-card shadow md:max-w-[1024px]">
        <div class="absolute left-4 top-4 flex items-center justify-center">
            <div class="flex h-full items-center justify-center gap-2">
                @if (isDarkTheme()) {
                    <app-button tooltip="Light mode" variant="link" icon="faSolidSun" (clicked)="toggleTheme()" />
                } @else if (!isDarkTheme()) {
                    <app-button tooltip="Dark mode" variant="link" icon="faSolidMoon" (clicked)="toggleTheme()" />
                }
                <div class="w-fit" placement="bottom-right" kendoTooltip title="{{ 'Change Language' | translate }}">
                    @if (currentLanguage()) {
                        <kendo-dropdownbutton
                            buttonClass="bg-card text-card-foreground border-none transition-none hover:bg-primary/20 bg-none"
                            [data]="languages">
                            @if (showLanguageFlag) {
                                <span class="p-0" [ngClass]="currentLanguage().iconClass"></span>
                            } @else {
                                {{ currentLanguage().languageId | uppercase }}
                            }
                        </kendo-dropdownbutton>
                    }
                </div>
                <app-button
                    class="visible xl:invisible"
                    variant="secondary"
                    size="sm"
                    icon="faSolidArrowRotateRight"
                    (click)="refresh()">
                    Refresh
                </app-button>
            </div>
        </div>
        <div class="grid grid-cols-2">
            <div class="col-span-2 rounded-s-lg md:col-span-1 md:h-full">
                <div class="flex h-full flex-col items-center justify-center pt-12">
                    <img class="w-1/2 object-contain" src="/assets/img/luka-koper-logo.svg" />
                    <img class="w-1/2 object-contain" src="/assets/img/vbs-logo.svg" alt="Vehicle Boooking System" />

                    <p class="pt-12 text-center text-4xl">{{ 'Driver Login' | translate }}</p>
                </div>
            </div>
            <div class="col-span-2 flex w-full flex-col gap-6 px-8 py-4 md:col-span-1 md:p-12">
                <form class="flex flex-col gap-4" (submit)="onSubmit()">
                    <span class="text-center text-3xl font-bold text-info">
                        {{ 'Place card on reader' | translate }}
                    </span>
                    <app-text-input
                        label="1."
                        id="badgeId"
                        required
                        placeholder="{{ 'Badge ID' | translate }}"
                        [autofocus]="true"
                        [isEditMode]="true"
                        [isDisabled]="true"
                        [(value)]="driver.badgeId">
                        <div
                            class="textbox-suffix k-input-button k-button-solid-base flex h-full items-center justify-center text-center">
                            <ng-icon name="faSolidAddressCard"></ng-icon>
                        </div>
                    </app-text-input>
                    <app-text-input
                        label="2."
                        id="pin"
                        required
                        placeholder="{{ 'PIN/Load ID or booking number' | translate }}"
                        [isEditMode]="true"
                        [isDisabled]="true"
                        [(value)]="driver.pin">
                        <div
                            class="textbox-suffix k-input-button k-button-solid-base flex h-full items-center justify-center text-center">
                            <ng-icon name="faSolidLock"></ng-icon>
                        </div>
                    </app-text-input>
                    <app-date-input
                        label="3."
                        id="driverDate"
                        name="driverDate"
                        required
                        format="dd.MM.yyyy"
                        [isEditMode]="true"
                        [isDisabled]="true"
                        [(value)]="driver.date" />
                    <hr />
                    <a href="{{ 'LinkLoginTermsAndConditions' | translate }}">
                        {{
                            'By signing in I agree with the terms and conditions of use for the electronic commerce services of the
                            Luka Koper group' | translate
                        }}
                    </a>
                    <div class="flex flex-col gap-4 text-center">
                        <app-button
                            class="w-full rounded-xl"
                            type="submit"
                            variant="success"
                            icon="faSolidArrowRightToBracket"
                            [disabled]="isBusy">
                            {{ 'Accept and sign In' | translate }}
                        </app-button>
                    </div>
                </form>
            </div>
        </div>
        <div class="flex flex-wrap items-center justify-between gap-4 p-4 text-muted">
            <div class="md:min-w-44">2024 &copy; {{ 'All rights reserved' | translate }}</div>
            @if (showTermsAndConditions) {
                <div class="md:min-w-44 md:text-center">
                    <a href="#">{{ 'Terms and conditions' | translate }}</a>
                </div>
            }
            <div class="md:min-w-44 md:text-end">{{ 'Version' | translate }} {{ version }}</div>
        </div>
    </div>
</div>
