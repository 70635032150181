import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { resolveProperty } from '@common/models/util';
import { CommonService } from '@common/services/common.service';
import { RememberStateService } from '@common/services/remember-state.service';
import { ActionBarGroup, ActionBarGroupNormalized, ButtonLabelSettings } from './action-bar.interface';

@Component({
    selector: 'app-action-bar',
    templateUrl: './action-bar.component.html',
    styleUrls: ['./action-bar.component.scss'],
    providers: [CommonService]
})
export class ActionBarComponent implements OnInit, OnChanges {
    @Input() items: ActionBarGroup[];
    @Input() collapsed = false;
    @Input() collapsible = true;
    @Input() buttonLabelsSettings: ButtonLabelSettings = { display: 'regular' };
    groups: ActionBarGroupNormalized[] = [];

    constructor(private rememberStateService: RememberStateService) {}

    ngOnInit(): void {
        this.collapsed = this.rememberStateService.get('actionBar');
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.items) {
            this.updateGroups();
        }
    }

    private updateGroups(): void {
        this.groups = this.normalizeGroups(this.items)
            .filter((group) => group.isVisible !== false)
            .map((group) => ({
                ...group,
                items: group.items.filter((item) => item.isVisible !== false)
            }))
            .filter((group) => group.items.length > 0);
    }

    private normalizeGroups(groups: ActionBarGroup[]): ActionBarGroupNormalized[] {
        if (!groups) {
            return [];
        }

        return groups.map((group) => ({
            ...group,
            isDisabled: resolveProperty(group.isDisabled),
            isVisible: resolveProperty(group.isVisible),
            items: group.items.map((item) => ({
                ...item,
                variant: item.variant == null ? 'primary' : item.variant,
                label: resolveProperty(item.label),
                icon: resolveProperty(item.icon),
                isDisabled: resolveProperty(item.isDisabled),
                isVisible: resolveProperty(item.isVisible)
            }))
        }));
    }

    toggleCollapsed() {
        this.collapsed = !this.collapsed;
        this.rememberStateService.set('actionBar', this.collapsed);
    }
}
