import { Injector } from '@angular/core';
import { PaginatedFilter } from '@common/interfaces/query.interface';
import { Codelists } from './codelists.service';

export interface CodeLists {
    name: string;
}

export interface CodeListFilter extends PaginatedFilter {
    codelistName: string;
    name?: string;
    id?: string; // this is not the localizedCode !!
    sortAscending?: string;
    sortBy?: string;
}

export interface CodeList {
    id: string;
    name: string;
    localizedCode: string;
}

export const fakedCodelists = {
    // these are entities that should be shown in in the codelists selection, but they are not technically codelists and are not editable in the codelist manager
    organization: 'Organization',
    organizationUnit: 'OrganizationUnit',
    permission: 'Permission',
    role: 'Role',
    user: 'User',
    vessel: 'Vessel',
    vesselType: 'VesselType',
    driver: 'Driver',
    fleet: 'Fleet'
} as const satisfies Record<string, string>;

export type FakedCodelists = (typeof fakedCodelists)[keyof typeof fakedCodelists];

export enum customCondition {
    Equal = 'Eq',
    Contains = 'Contains'
}

/**
 * Structure used to send custom condition to codelist query creator.
 * Can create sub set of data for codelist/ combobox options
 * (Ex:. return just trucks from fleet => { name: 'FleetType', condition: customCondition.Equal, value: FleetTypeCodes.Truck },
 * return just drivers from organization xxx => { name: 'Organization', condition: customCondition.Equal, value: this.model.organizationId })
 * @param condition - enum[{@link customCondition}] which represent operation you can use in custom filter
 * @param name - name of parameter form codelist table, you wish to use for filter condition (Ex:. table.param1 -> param1, table.param1.id -> param1)
 * @param value - value you wish to use against codelist table parameter
 */
export interface CustomFilter2 {
    condition: customCondition;
    name: string;
    value: string;
}

export interface CodelistParams {
    name: Codelists | FakedCodelists;
    selectedIds?: string[];
    omittedIds?: string[];
    filter?: string;
    customFilter?: string;
    customFilter2?: CustomFilter2[];
    take?: number;
    useCache?: boolean;
    injector?: Injector;
}

// GetCodeListItemFullQuery
export interface CodeListItemFull {
    id: string;
    name?: string;
    localizedCode?: string;
    translations?: CodeListTranslation[];
    active?: boolean;
    version?: number;
    createdDate?: string;
    lastModifiedDate?: string;
    [key: string]: any;
}

export interface CodeListItemFullQuery {
    item: CodeListItemFull;
    properties: CodeListProperty[];
    default: CodeListItemFull;
}

export interface CodeListTranslation {
    localizedCode: string;
    languageId: string;
    language: Language;
    name: string;
    id: number;
}

export interface Language {
    name: string;
    localizedCode: string;
    active: boolean;
    version: number;
    createdDate: string;
    lastModifiedDate: string;
    id: string;
}

export interface CodeListProperty {
    key: string;
    label: string;
    dataType: 'datetime' | 'text' | 'number' | 'boolean' | 'codeList';
    codeListName: Codelists;
    defaultValue: any;
}

export interface CodeListItemFullFilter {
    codelistName: string;
    id: string;
}
// CodeListItemFullQuery above

export interface CodeListAddTranslationParameters {
    CodelistName: string;
    CodelistId: string;
    LanguageId: string;
    Name: string;
    LocalizedCode: string;
}

export interface TranslationsGridDataItem {
    name: string;
    localizedCode: string;
    languageId: string;
}

export interface CodeListRemoveTranslationParameters {
    CodelistName: string;
    CodelistId: string;
    Id: number;
    LanguageId: string;
}

export interface CodeListUpdateTranslationParameters {
    CodeListName: string;
    CodeListLanguageId: number;
    LanguageId: string;
    Name: string;
    LocalizedCode: string;
}

export type CodeListUpdateParameters = Omit<CodeListItemFullQuery, 'default'> & {
    codelistName: string;
    codeListId: string;
};

export interface CodeListCreateParameters {
    CodelistName: string;
    Item: CodeListItemFull;
}

export interface CodeListDeleteParameters {
    CodelistName: string;
    Id: string;
}
