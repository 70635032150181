import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'empty'
})
export class EmptyPipe implements PipeTransform {
    transform(value: string | number | boolean): string {
        if (value === null || value === undefined) {
            return '-';
        }

        if (typeof value === 'string' && value.trim() === '') {
            return '-';
        }

        if (typeof value === 'number' && isNaN(value)) {
            return '-';
        }

        return value.toString();
    }
}
