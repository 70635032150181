import { Component, Input } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { NumberInputConfig } from '../input.type';

/**
 * Number input component.
 * @param min - The minimum number.
 * @param max - The maximum number.
 * @param format - The format of the number.
 * @param decimals - The number of decimals.
 * @param maxLength - The maximum length of the number.
 */
@Component({
    selector: 'app-number-input',
    templateUrl: './number-input.component.html',
    providers: [{ provide: BaseInputComponent, useExisting: NumberInputComponent }]
})
export class NumberInputComponent extends BaseInputComponent<number> {
    @Input() min: NumberInputConfig['min'] = 0;
    @Input() max: NumberInputConfig['max'];
    @Input() format: NumberInputConfig['format'] = '#';
    @Input() decimals: NumberInputConfig['decimals'] = 2;
    @Input() maxLength: NumberInputConfig['maxLength'];
}
