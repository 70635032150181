import { fakedCodelists } from '@administration/codelists/codelists.interface';
import { Component, ComponentRef, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ColumnSettings, EditableColumnSettings, GridCellContext } from '@common/interfaces/column-settings.interface';
import { ValueOf } from '@common/models/util.interface';
import { ViewMode } from '@common/models/view-mode';
import { environment } from '@environments/environment.base';

@Component({
    selector: 'app-grid-cell-template',
    templateUrl: './grid-cell-template.component.html'
})
export class GridCellTemplateComponent implements OnInit, OnDestroy {
    @Input() defaultViewMode: ValueOf<typeof ViewMode>;
    @Input() parentRoute: string;
    @Input() row: any;
    @Input() column: ColumnSettings | EditableColumnSettings;
    defaultTimeFormat: string = environment.settings.appControl.timeFormat;
    fakedCodelistArray: string[] = Object.entries(fakedCodelists).map(([, value]) => value);

    private componentRef: ComponentRef<any> | null = null;

    constructor(private viewContainerRef: ViewContainerRef) {}

    ngOnInit() {
        if (this.column.templateComponent) {
            this.createTemplateComponent();
        }
    }

    ngOnDestroy() {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }

    private createTemplateComponent() {
        this.viewContainerRef.clear();

        const context: GridCellContext = {
            row: this.row,
            column: this.column,
            parentRoute: this.parentRoute,
            defaultViewMode: this.defaultViewMode
        };

        this.componentRef = this.viewContainerRef.createComponent(this.column.templateComponent);
        this.componentRef.instance.context = context;

        if (this.column.templateInputs) {
            const inputs = this.column.templateInputs(this.row);
            Object.assign(this.componentRef.instance, inputs);
        }
    }
}
