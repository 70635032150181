import { Component, computed, Input } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { DateInputConfig } from '../input.type';

/**
 * Date input component.
 * @param format - The format of the date.
 * @param minDate - The minimum date.
 * @param maxDate - The maximum date.
 * @param disabledDates - The disabled dates.
 */
@Component({
    selector: 'app-date-input',
    templateUrl: './date-input.component.html',
    providers: [{ provide: BaseInputComponent, useExisting: DateInputComponent }]
})
export class DateInputComponent extends BaseInputComponent<DateInput> {
    @Input() format: DateInputConfig['format'] = 'dd. MM. yyyy';
    @Input() minDate: DateInputConfig['minDate'];
    @Input() maxDate: DateInputConfig['maxDate'];
    @Input() disabledDates: DateInputConfig['disabledDates'];

    dateValue = computed<Date>(() => {
        if (!this.value()) return null;
        if (this.value() instanceof Date) {
            return this.value() as Date;
        }
        const date = new Date(this.value());
        return isNaN(date.getTime()) ? null : date;
    });

    override onValueChange(value: DateInput) {
        super.onValueChange(value);
        this.value.set(value);
    }
}

export type DateInput = Date | string;
