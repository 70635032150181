<div class="flex h-full flex-col items-center justify-center">
    <div class="w-full rounded-lg bg-card shadow md:max-w-[1024px]">
        <div class="grid grid-cols-2">
            <div
                class="relative col-span-2 rounded-s-lg bg-cover md:col-span-1 md:h-full"
                style="background-image: url('/assets/img/front.jpg')">
                <img class="absolute left-4 top-3 w-64 object-cover" src="{{ '/assets/img/luka-koper-logo.svg' }}" />
            </div>
            <div class="col-span-2 flex w-full flex-col gap-3 px-4 py-2 md:col-span-1 md:px-8 md:py-4">
                <div class="flex w-full items-start justify-between">
                    <div class="flex items-start pt-1">
                        @if (isDarkTheme()) {
                            <app-button
                                tooltip="Light mode"
                                variant="link"
                                icon="faSolidSun"
                                (clicked)="toggleTheme()" />
                        } @else if (!isDarkTheme()) {
                            <app-button
                                tooltip="Dark mode"
                                variant="link"
                                icon="faSolidMoon"
                                (clicked)="toggleTheme()" />
                        }
                        <div
                            class="w-fit"
                            placement="bottom-right"
                            kendoTooltip
                            title="{{ 'Change Language' | translate }}">
                            @if (currentLanguage()) {
                                <kendo-dropdownbutton
                                    buttonClass="bg-card text-card-foreground border-none transition-none hover:bg-primary/20 bg-none pt-1.5"
                                    [data]="languages">
                                    @if (showLanguageFlag) {
                                        <span class="p-0" [ngClass]="currentLanguage().iconClass"></span>
                                    } @else {
                                        <span>
                                            {{ currentLanguage().languageId | uppercase }}
                                        </span>
                                    }
                                </kendo-dropdownbutton>
                            }
                        </div>
                        <a class="text-foreground outline-0" href="mailto:pomoc@actual.si">
                            <app-button icon="faSolidQuestion" variant="link"></app-button>
                        </a>
                    </div>

                    <img class="h-20 object-contain" src="/assets/img/vbs-logo.svg" alt="Vehicle Boooking System" />
                </div>
                <div class="mt-12 flex justify-start gap-4">
                    @if (showDriverLogin) {
                        <h3 class="text-3xl">{{ 'Driver Login' | translate }}</h3>
                    } @else {
                        <h2 class="text-3xl">{{ 'User Login' | translate }}</h2>
                    }
                </div>
                <form class="mb-12 flex w-full flex-grow flex-col gap-4" (submit)="onSubmit()">
                    @if (!showDriverLogin) {
                        <app-text-input
                            id="username"
                            #username
                            required
                            placeholder="{{ 'Username' | translate }}"
                            [isRequired]="true"
                            [autofocus]="true"
                            [isEditMode]="true"
                            [(value)]="user.username">
                            <div
                                class="textbox-suffix k-input-button k-button-solid-base flex h-full items-center justify-center text-center">
                                <ng-icon name="faSolidAddressCard"></ng-icon>
                            </div>
                        </app-text-input>
                        <app-text-input
                            password="true"
                            id="password"
                            #password
                            required
                            placeholder="{{ 'Password' | translate }}"
                            [isRequired]="true"
                            [isEditMode]="true"
                            [(value)]="user.password">
                            <div
                                class="textbox-suffix k-input-button k-button-solid-base flex h-full items-center justify-center text-center">
                                <ng-icon name="faSolidLock"></ng-icon>
                            </div>
                        </app-text-input>
                    } @else {
                        <app-text-input
                            id="badgeId"
                            #badgeId
                            required
                            placeholder="{{ 'Badge ID' | translate }}"
                            [autofocus]="true"
                            [isRequired]="true"
                            [isEditMode]="true"
                            [(value)]="driver.badgeId">
                            <div
                                class="textbox-suffix k-input-button k-button-solid-base flex h-full items-center justify-center text-center">
                                <ng-icon name="faSolidAddressCard"></ng-icon>
                            </div>
                        </app-text-input>
                        <app-text-input
                            id="pin"
                            #pin
                            required
                            placeholder="{{ 'PIN/Load ID or booking number' | translate }}"
                            [isEditMode]="true"
                            [isRequired]="true"
                            [(value)]="driver.pin">
                            <div
                                class="textbox-suffix k-input-button k-button-solid-base flex h-full items-center justify-center text-center">
                                <ng-icon name="faSolidLock"></ng-icon>
                            </div>
                        </app-text-input>
                        <app-date-input
                            id="driverDate"
                            #driverDate
                            name="driverDate"
                            required
                            format="dd.MM.yyyy"
                            [isEditMode]="true"
                            [isRequired]="true"
                            [(value)]="driver.date" />
                    }
                    <div class="flex items-start">
                        @if (!showDriverLogin) {
                            <app-boolean-input
                                id="agreeToTerms"
                                name="agreeToTerms"
                                isEditMode="true"
                                [(value)]="agreeToTerms" />
                        }
                        <label class="ml-2 text-xs" for="agreeToTerms">
                            <a class="block" href="{{ 'LinkLoginTermsAndConditions' | translate }}">
                                {{
                                    'I agree with the terms and conditions of use for the electronic commerce services of the
                                    Luka Koper group' | translate
                                }}
                            </a>
                        </label>
                    </div>
                    <div class="flex flex-col gap-2 text-center">
                        <app-button
                            class="w-full rounded-xl"
                            type="submit"
                            variant="success"
                            icon="faSolidArrowRightToBracket"
                            [disabled]="isBusy || (!agreeToTerms && !showDriverLogin)">
                            @if (!showDriverLogin) {
                                <span>{{ 'Sign In' | translate }}</span>
                            } @else {
                                <span>{{ 'Accept and sign In' | translate }}</span>
                            }
                        </app-button>
                        @if (showForgetPassword && !showDriverLogin) {
                            <app-button
                                class="ml-auto text-xs text-muted"
                                size="sm"
                                variant="link"
                                (click)="requestPasswordReset()">
                                {{ 'Forgot password' | translate }}
                            </app-button>
                        }
                    </div>
                </form>

                <div class="flex items-center justify-between">
                    <app-button class="px-3" size="sm" variant="warning" (click)="toggleUserDriver()">
                        @if (showDriverLogin) {
                            <span>{{ 'Switch to User login' | translate }}</span>
                        } @else {
                            <span>{{ 'Switch to Driver login' | translate }}</span>
                        }
                    </app-button>
                    <a href="{{ 'LinkLoginInformationForTruckers' | translate }}">
                        {{ 'Information for truckers' | translate }}
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-2 flex flex-wrap items-center justify-between gap-4 text-xs text-muted">
        <div>2024 &copy; {{ 'All rights reserved' | translate }}</div>
        @if (showTermsAndConditions) {
            <div>
                <a href="#">{{ 'Terms and conditions' | translate }}</a>
            </div>
        }
        <div>{{ 'Version' | translate }} {{ version }}</div>
    </div>
</div>
