import { CodelistParams } from '@administration/codelists/codelists.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector, inject } from '@angular/core';
import { ValueOf } from '@common/models/util.interface';
import { environment } from '@environments/environment';
import { injectMutation, injectQuery, injectQueryClient } from '@ngneat/query';
import { firstValueFrom, tap } from 'rxjs';

export const Action = {
    Create: 'Create',
    Edit: 'Edit'
} as const;

export interface SaveCommand<T> {
    action: ValueOf<typeof Action>;
    saveModel: T;
}

@Injectable()
export class QueryService {
    public queryClient = injectQueryClient();
    public useMutation = injectMutation();
    private http = inject(HttpClient);

    /**
     * **Warning:** Direct use of this method is discouraged as it circumvents the intended `ngneat/query` patterns and caching.
     * Try using {@link getCommandMutation} instead
     *
     * Execute a command by making a direct HTTP POST request.
     *
     * @param command The command endpoint to hit.
     * @param data The payload for the command.
     * @returns A Promise that resolves to the response of the command.
     */
    command<TReturn, TData = any>(command: string, data: TData) {
        return firstValueFrom(this.http.post<TReturn>(`${environment.apiUrl}/command/${command}`, data));
    }

    /**
     * **Warning:** Direct use of this method is discouraged as it circumvents the intended `ngneat/query` patterns and caching.
     * Try using {@link getQuery} instead.
     *
     * Perform a query by making a direct HTTP POST request.
     *
     * @param query The query endpoint to hit.
     * @param data The payload for the query.
     * @returns A Promise that resolves to the response of the query.
     */
    query<TReturn, TData = any>(query: string, data: TData) {
        return firstValueFrom(this.http.post<TReturn>(`${environment.apiUrl}/query/${query}`, data));
    }

    /**
     * Fetches data from the server using the specified query and parameters.
     * @param query The name of the query to execute.
     * @param data The data to send to the server.
     * @param options The options for the query.
     * @param options.injector The injector to use for the query. Use when calling from a different context, eg. outside constructor or field initialization.
     * @returns The query object for the query.
     * @example
     * ## Using observable
     * const query = queryService.getQuery('Users', { skip: 0, take: 1000, organizationId: 1 });
     * const users = await query.result$;
     *
     * ## Using signal
     * const query = queryService.getQuery('Users', { skip: 0, take: 1000, organizationId: 1 });
     * const users = await query.result;
     *
     */
    getQuery<TReturn, TData = any>(query: string, data: TData, options?: any & { injector?: Injector }) {
        const useQuery = injectQuery({ injector: options?.injector });
        return useQuery<TReturn>({
            queryKey: [query, { ...data }],
            queryFn: () => this.http.post<TReturn>(`${environment.apiUrl}/query/${query}`, data),
            ...options
        });
    }

    /**
     * Fetches a codelist from the server using the specified parameters.
     *
     * @param name The name of the codelist to fetch.
     * @param selectedIds The IDs of the codelist items to select.
     * @param filter The filter to apply to the codelist items.
     * @param customFilter The custom filter to apply to the codelist items.
     * @param take The maximum number of codelist items to fetch.
     * @param omittedIds The IDs of the codelist items to omit.
     * @param injector The injector to use for the query. Use when calling from a different context, eg. outside constructor or field initialization.
     * @returns The query object for the codelist.
     */
    getCodelistQuery<T>({
        name,
        selectedIds = [],
        filter = '',
        customFilter = '',
        customFilter2 = [],
        take = 100,
        omittedIds = [],
        injector
    }: CodelistParams) {
        const useQuery = injectQuery({ injector });
        return useQuery({
            queryKey: ['codelist', name, selectedIds, take, customFilter, customFilter2, filter, omittedIds] as const,
            injector,
            queryFn: () =>
                this.http.post<T>(`${environment.apiUrl}/query/CodeList`, {
                    name,
                    selectedIds,
                    filter,
                    customFilter,
                    customFilter2,
                    take,
                    omittedIds
                }),
            staleTime: Infinity
        });
    }

    /**
     * Used to mutate data on the server using a command.
     * @returns mutation object for the command, with following parameters:
     * @param command The name of the command to execute.
     * @param data The data to send to the server.
     * @param invalidate The query key(s) to invalidate after the command is executed.
     */
    getCommandMutation<TReturn, TData = object>() {
        return this.useMutation({
            mutationFn: ({
                command,
                data,
                invalidate
            }: {
                command: string;
                data: TData;
                invalidate?: string | string[];
            }) =>
                this.http.post<TReturn>(`${environment.apiUrl}/command/${command}`, data).pipe(
                    tap(() => {
                        if (Array.isArray(invalidate)) {
                            Promise.all([
                                invalidate.map((queryKey) =>
                                    this.queryClient.invalidateQueries({ queryKey: [queryKey] })
                                )
                            ]);
                        } else {
                            this.queryClient.invalidateQueries({ queryKey: [invalidate] });
                        }
                    })
                )
        });
    }

    prefetch<TReturn, TData = any>(query: string, data: TData) {
        return this.queryClient.prefetchQuery({
            queryKey: [query, { ...data }],
            queryFn: () => this.query<TReturn, TData>(query, data)
        });
    }

    private toArray(input: string | string[]) {
        return Array.isArray(input) ? input : [input];
    }
}
